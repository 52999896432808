import React, { useEffect } from 'react';
import { useForm, Controller, FieldValues } from 'react-hook-form';
import { useAddGroupMutation } from '../services/admin/inventoryApi';
import "../styles/AddGroup.scss";
import { useNavigate } from 'react-router-dom';
import { Input, Button, Form, Typography, Switch } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useLazyGetUserQuery } from '../services/admin/adminAuth';

const { Title } = Typography;

const AddGroup: React.FC = () => {
  const { register, handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      name: '',
      blurb: "",
      is_rep_group:false,
    }
  });

  const [addGroup, { isLoading, isError, isSuccess }] = useAddGroupMutation();
  const navigate = useNavigate();
  const [getUser, { data: user }] = useLazyGetUserQuery();
  useEffect(() => {
    getUser();

   
  }, [getUser]);
  const onSubmit = async (data: FieldValues) => {
    try {
      
      await addGroup(data).unwrap();
      console.log('Group added successfully:', data);
      navigate(-1);
    } catch (error) {
      console.error('Failed to add group:', error);
    }
  };

  return (
    <div className="add-group-container">
      <Title level={2}>Add Group</Title>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical" className="add-group-form">
        <Form.Item
          label="Name"
          validateStatus={errors.name ? 'error' : ''}
          help={errors.name ? errors.name.message : ''}
        >
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Name is required' }}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                placeholder="Enter group name"
              />
            )}
          />
        </Form.Item>
     {user?.scope ==="internal_admin"&&   <Form.Item
          label="Is Rep Group"
          validateStatus={errors.is_rep_group ? 'error' : ''}
          help={errors.is_rep_group ? errors.is_rep_group.message : ''}
        >
          <Controller
            name="is_rep_group"
            control={control}
            render={({ field }) => (
              <Switch checked={field.value} onChange={field.onChange} />
            )}
          />
        </Form.Item>}
        <Form.Item
          label="Description"
          validateStatus={errors.blurb ? 'error' : ''}
          help={errors.blurb ? errors.blurb.message : ''}
        >
          <Controller
            name="blurb"
            control={control}
            rules={{ required: 'Description is required' }}
            render={({ field }) => (
              <TextArea
                {...field}
                rows={4}
                placeholder="Enter Description"
              />
            )}
          />
        </Form.Item>
        

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>Add Group</Button>
        </Form.Item>

        {isError && <p>Failed to add group. Please try again.</p>}
        {isSuccess && <p>Group added successfully!</p>}
      </Form>
    </div>
  );
};

export default AddGroup;
