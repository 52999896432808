// src/components/AccountssignupModal.tsx

import React from 'react';
import { Modal, Table } from 'antd';
import { useGetReportSignupsQuery } from '../services/admin/inventoryApi';

interface AccountsignupModalProps {
  visible: boolean;
  onClose: () => void;
  campaignId: string;
  isMobile: boolean;
}

const AccountsignupModal: React.FC<AccountsignupModalProps> = ({ visible, onClose, campaignId ,isMobile}) => {
  const { data: invitesData, isLoading: isLoadingInvites } = useGetReportSignupsQuery({ campaignId, skip: 0, limit: 1000 });

  const columns = [
    { title: 'Email Address', dataIndex: 'email_address', key: 'email_address' },
    { title: 'Account Created', dataIndex:'account_created', key: 'account_created',render: (text: boolean) => (text ? 'Yes' : 'No'),},
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Photo Uploaded', dataIndex:'account_photo_created', key: 'account_photo_created',render: (text: boolean) => (text ? 'Yes' : 'No'),},
    // { title: 'Account blurb created', dataIndex:'account_blurb_created', key: 'account_blurb_created',render: (text: boolean) => (text ? 'Yes' : 'No'),},
    // { title: 'Account goal created', dataIndex:'account_goal_created', key: 'account_goal_created',render: (text: boolean) => (text ? 'Yes' : 'No'),},
    { title: 'First Sale', dataIndex:'account_first_sale_created', key: 'account_first_sale_created',render: (text: boolean) => (text ? 'Yes' : 'No'),},

  ];

  return (
    <Modal
      title="Account Signups"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Table
      // @ts-ignore
        dataSource={invitesData|| []}
        loading={isLoadingInvites}
        rowKey="email_address"
        columns={columns}

        scroll={{ x: isMobile?'100vw':undefined }} // Enable horizontal scroll

  
      />
    </Modal>
  );
};

export default AccountsignupModal;
