import React, { useEffect, useState } from 'react';
import '../styles/EditGroup.scss';  // Update with relevant styles
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetGroupsQuery, useEditGroupsMutation, useGetUsersQuery } from '../services/admin/inventoryApi';  // Update with correct API hooks
import { Group, User } from '../types.ts';  // Update with correct types
import { Form, Input, Button, Typography, Drawer, List, Checkbox, message, Switch } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { useLazyGetUserQuery } from '../services/admin/adminAuth';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../slices/admin/activeTabSlice';

const { Title } = Typography;

const EditGroup: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [inviteLink, setInviteLink] = useState<string>('');
  const { data: groupsData, error: groupsError, isLoading: groupsLoading } = useGetGroupsQuery({ skip: 0, limit: 1000,
    //@ts-ignore
    is_rep_group:null });
  const { data: usersData, error: usersError, isLoading: usersLoading } = useGetUsersQuery({ skip: 0, limit: 1000, group_id: parseInt(id || "0") });
  const [updateGroup, { isLoading: isUpdating, isError, isSuccess }] = useEditGroupsMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<Group>({
    defaultValues: {
      name: '',
      blurb: '',
      users: [],
      is_rep_group: false,
      is_signup_disabled: false,
    }
  });

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [selectedUserNames, setSelectedUserNames] = useState<string>('');
  const [getUser, { data: user }] = useLazyGetUserQuery();
  useEffect(() => {
    getUser();

   
  }, [getUser]);
  useEffect(() => {
    if (groupsData) {
      const group = groupsData?.find((item: Group) => item.id === parseInt(id || "0"));
      if (group) {
        setValue('name', group.name);
        setValue('blurb', group.blurb);
        setValue('is_rep_group', group.is_rep_group);
        setValue('is_signup_disabled', group.is_signup_disabled);
        setValue('users', group.users ?? []);
        setSelectedUsers(group.users ?? []);
        setSelectedUserNames(group.users.map(user => user.full_name).join(', '));
        setInviteLink(`${process.env.REACT_APP_BASE_URL_FE}signup/${group.id}`);
      }
    }
  }, [groupsData, id, setValue, usersData]);

  const onSubmit = async (data: Group) => {
    const updatedData = {
      ...data,
      users: selectedUsers.map(user => user.id)
    };

    try {
      await updateGroup({ ...updatedData, id: parseInt(id || "0") }).unwrap();
      console.log('Group updated successfully:', updatedData);
      navigate(-1);
    } catch (error) {
      console.error('Failed to update group:', error);
    }
  };

  const handleDrawerOpen = () => {
    setIsDrawerVisible(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  const handleUserToggle = (user: User) => {
    setSelectedUsers((prevSelectedUsers) => {
      const updatedSelectedUsers = prevSelectedUsers.some(selectedUser => selectedUser.id === user.id)
        ? prevSelectedUsers.filter(selectedUser => selectedUser.id !== user.id)
        : [...prevSelectedUsers, user];

      setSelectedUserNames(updatedSelectedUsers.map(user => user.full_name).join(', '));

      return updatedSelectedUsers;
    });
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(inviteLink);
    message.success('Invite link copied to clipboard');
  };

  useEffect(() => {
    setValue('users', selectedUsers);
  }, [selectedUsers, setValue]);

  return (
    <div className="edit-group-container">
      <Title level={2}>Edit Group</Title>
      {groupsLoading || usersLoading ? (
        <p>Loading...</p>
      ) : groupsError || usersError ? (
        <p>Failed to load data.</p>
      ) : (
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical" className="edit-group-form">
          <Form.Item
            label="Name"
            validateStatus={errors.name ? 'error' : ''}
            help={errors.name ? errors.name.message : ''}
          >
            <Controller
              name="name"
              control={control}
              rules={{ required: 'Name is required' }}
              render={({ field }) => (
                <Input type="text" {...field} />
              )}
            />
          </Form.Item>
      { user?.scope ==="internal_admin"&&  <Form.Item
          label="Is Rep Group"
          validateStatus={errors.is_rep_group ? 'error' : ''}
          help={errors.is_rep_group ? errors.is_rep_group.message : ''}
        >
          <Controller
            name="is_rep_group"
            control={control}
            render={({ field }) => (
              <Switch checked={field.value} onChange={field.onChange} />
            )}
          />
        </Form.Item>}

        <Form.Item
          label="Disable Signup"
          validateStatus={errors.is_signup_disabled ? 'error' : ''}
          help={errors.is_signup_disabled ? errors.is_signup_disabled.message : ''}
        >
          <Controller
            name="is_signup_disabled"
            control={control}
            render={({ field }) => (
              <Switch checked={field.value} onChange={field.onChange} />
            )}
          />
        </Form.Item>


          <Form.Item
            label="Description"
            validateStatus={errors.blurb ? 'error' : ''}
            help={errors.blurb ? errors.blurb.message : ''}
          >
            <Controller
              name="blurb"
              control={control}
              rules={{ required: 'Blurb is required' }}
              render={({ field }) => (
                <TextArea  {...field}  rows={4}/>
              )}
            />
          </Form.Item>

          <Form.Item
            label="Users"
            validateStatus={errors.users ? 'error' : ''}
            help={errors.users ? errors.users.message : ''}
          >
             <Input
              value={selectedUserNames}
              readOnly
              style={{ marginTop: '10px' }}
            />
            <Button type="primary" onClick={handleDrawerOpen}>View Users</Button>
           
          </Form.Item>
          <Form.Item
            label="Invite Link"
          >
            <Input
              value={inviteLink}
              readOnly
              addonAfter={<Button icon={<CopyOutlined />} onClick={copyToClipboard}>Copy</Button>}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={groupsLoading || isUpdating}>Save Changes</Button>
          </Form.Item>

          {isError && <p>Failed to update group. Please try again.</p>}
          {isSuccess && <p>Group updated successfully!</p>}
        </Form>
      )}

      <Drawer
        title="Users"
        width={400}
        onClose={handleDrawerClose}
        visible={isDrawerVisible}
      >
        <List
          dataSource={usersData?.data?.filter(user => user?.in_group === true) ?? []}
          renderItem={(user: User) => (
            <List.Item key={user.id} onClick={()=>{navigate(`/edit-user/${user.id}`)
            dispatch(setActiveTab(`tab6` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
            }}
            style={{
              cursor:"pointer"
            }}
            >
              {/* <Checkbox
                checked={selectedUsers.some(selectedUser => selectedUser.id === user.id)}
                onChange={() => handleUserToggle(user)}
              > */}
                {user.full_name} - {user.email}
              {/* </Checkbox> */}
            </List.Item>
          )}
        />
      </Drawer>
    </div>
  );
};

export default EditGroup;
