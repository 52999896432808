import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Form, Grid, Input, Typography, theme, Spin } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useSignInMutation, useLazyGetUserQuery } from '../services/admin/adminAuth';
import { signIn as signInAction, setUserDetails } from '../slices/admin/adminUserSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../store';  // Import your store's root state

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

const SignIn: React.FC = () => {
  const { token } = useToken();
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signIn, { error , isLoading: loading, data }] = useSignInMutation();
  const [getUser] = useLazyGetUserQuery();
   // Select the token from the store
   const sessionToken = useSelector((state: RootState) => state.adminUser.token);

  interface FetchBaseQueryErrorData {
    detail?: string;
  }
  
  // Error handling function
  function getErrorMessage(error: FetchBaseQueryError): string {
    if ('data' in error) {
      // If error has data property, assert its type
      const fetchBaseQueryErrorData = (error as FetchBaseQueryError).data as FetchBaseQueryErrorData;
      return fetchBaseQueryErrorData?.detail ?? 'Failed to sign in. Please try again.';
    } else {
      // If error doesn't have data property
      return 'Failed to sign in. Please try again.';
    }
  }
  
  const handleSignIn = async (values: { email: string; password: string }) => {
    const { email, password } = values;
    try {
      const result = await signIn({ email: email.trim().toLowerCase(), password: password.trim() }).unwrap();
      dispatch(signInAction({ token: result.access_token, email: email.trim() }));
      console.log(result,"ygh")
      // if (result.access_token) {
      //   navigate("/dashboard/products");
      // }
    } catch (err) {
      console.error('Failed to sign in:', err);
    }
  };

  const navigateToForgotPassword = ()=>{
    navigate("/forgot-password");
  }
  useEffect(() => {
    if (sessionToken) {
      // Optional: Verify token by fetching user details if needed
      getUser().unwrap().then((result) => {
        if(result.scope==="student"){
          navigate("/dashboard/user-dashboard");
        }else{
          navigate("/dashboard/products");
        }

        // navigate("/dashboard/products");
      }).catch(() => {
        // Optionally handle the case where the token is invalid
      });
    }
  }, [sessionToken, getUser, navigate]);


  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      margin: "0 auto",
      padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px"
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%"
    },
    forgotPassword: {
      float: "right"
    },
    header: {
      marginBottom: token.marginXL
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
    },
    text: {
      color: token.colorTextSecondary
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
    }
  };
console.log(data,error)
  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <div style={styles.header}>
          <Title style={styles.title}>Sign in</Title>
          <Text style={styles.text}>
            Welcome back! Please enter your details below to sign in.
          </Text>
        </div>
        <Form
          name="normal_login"
          initialValues={{
            remember: true,
          }}
          onFinish={handleSignIn}
          layout="vertical"
          requiredMark="optional"
        >
         <Form.Item
  name="email"
  rules={[
    {
      type: "email",
      required: true,
      message: "Please input a valid Email!",
    },
  ]}
  getValueFromEvent={(e) => e.target.value.trim()} // Trims spaces
>
  <Input
    prefix={<MailOutlined />}
    placeholder="Email"
  />
</Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          {error && (
            <Form.Item>
              <Text type="danger">
               {error? getErrorMessage(error as FetchBaseQueryError) :'Failed to sign in. Please try again.'} 
              </Text>
            </Form.Item>
          )}
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <a style={styles.forgotPassword} href="" onClick={navigateToForgotPassword}>
              Forgot password?
            </a>
          </Form.Item>
          <Form.Item style={{ marginBottom: "0px" }}>
            <Button block type="primary" htmlType="submit" loading={loading}>
              Log in
            </Button>
          </Form.Item>
        </Form>
        {loading && (
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <Spin />
          </div>
        )}
      </div>
    </section>
  );
};

export default SignIn;
